<template>
  <div class="page-content">
    <el-steps :active="active" finish-status="success" style="margin-bottom: 20px">
      <el-step title="老师"></el-step>
      <el-step title="学生"></el-step>
      <el-step title="信息"></el-step>
    </el-steps>
    <div class="content-box" v-loading="loading">
      <div v-show="active === 0">
        <SectionTitle title="企业老师选择" />
        <div class="mb-16 u-flex u-row-between">
          <div>
            <span>合作企业：</span>
            <el-select v-model="layoutInfo.EnterpriseId" @change="getEnterpriseTeacherList" filterable class="mr-16" :disabled="isView">
              <el-option v-for="item in enterpriseList" :key="item.Id" :label="item.EnterpriseName" :value="item.Id"></el-option>
            </el-select>
          </div>
          <span v-if="selectEnterpriseTeacherList.length > 0">已选：<span class="number">{{
              selectEnterpriseTeacherList.length
            }}</span>个</span>
        </div>
        <el-table :class="isView ? 'disable' : ''" ref="EnterpriseTeacherTable" :data="EnterpriseTeacherTableData" style="width: 100%" border v-loading="tableLoading" height="450px"
          @selection-change="handleSelectionEnterpriseTeacher">
          <el-table-column :selectable="checkSelectable" type="selection" width="55">
          </el-table-column>
          <el-table-column label="姓名" prop="Name"></el-table-column>
          <el-table-column label="部门" prop="OrganizationName"></el-table-column>
          <el-table-column label="职位" prop="Position"></el-table-column>
        </el-table>

        <SectionTitle title="学校老师选择" />
        <div class="mb-16 u-flex u-row-between">
          <!-- <div>
            <span>企业单位：</span>
            <el-select v-model="layoutInfo.EnterpriseId" @change="getJobList" filterable class="mr-16" :disabled="isView">
              <el-option v-for="item in enterpriseList" :key="item.Id" :label="item.EnterpriseName" :value="item.Id"></el-option>
            </el-select>
          </div> -->
          <span v-if="selectSchoolTeacherList.length > 0">已选：<span class="number">{{
              selectSchoolTeacherList.length
            }}</span>个</span>
        </div>
        <el-table :class="isView ? 'disable' : ''" ref="SchoolTeacherTable" :data="SchoolTeacherTableData" style="width: 100%" border v-loading="tableLoading" height="450px"
          @selection-change="handleSelectionSchoolTeacher">
          <el-table-column :selectable="checkSelectable" type="selection" width="55" />
          <el-table-column label="姓名" prop="Name" />
          <el-table-column label="部门" prop="OrganizationName" />
          <el-table-column label="职位" prop="Position" />
        </el-table>
      </div>

      <div v-show="active === 1">
        <SectionTitle title="添加学生" />
        <div class="mb-16 u-flex u-row-between">
          <!-- <div class="u-flex-1">
            <span>专业：</span>
            <el-select v-model="layoutInfo.MajorId" @change="getGrade" filterable class="mr-16" :disabled="isView" @visible-change="handleVisibleChange">
              <el-option v-for="item in majorList" :key="item.Id" :label="item.MajorBase" :value="item.Id"></el-option>
            </el-select>
            <span>年级：</span>
            <el-select v-loading="gradeListLoading" v-model="layoutInfo.Grade" @change="getClassInfo" class="mr-16" :disabled="isView" style="width:20%" @visible-change="handleVisibleChange">
              <el-option v-for="(item, index) in gradeList" :key="index" :label="item" :value="item"></el-option>
            </el-select>
            <span>班级：</span>
            <el-select v-loading="classInfoLoading" v-model="layoutInfo.ClassId" @change="getStudent" class="mr-16" :disabled="isView" style="width:20%" @visible-change="handleVisibleChange">
              <el-option v-for="item in classInfoList" :key="item.Id" :label="item.Name" :value="item.Id"></el-option>
            </el-select>
          </div> -->
          <span v-if="selectStudentList.length > 0">已选：<span class="number">{{ selectStudentList.length }}</span>名</span>
        </div>
        <el-table ref="studentTable" :data="studentTableData" style="width: 100%" border v-loading="tableLoading" height="450px" @selection-change="handleSelectionStudent">
          <el-table-column :selectable="checkSelectable" type="selection" width="55">
          </el-table-column>
          <el-table-column label="姓名" prop="Name"></el-table-column>
          <el-table-column label="学号" prop="Code"></el-table-column>
          <!-- <el-table-column label="手机号" prop="Phone"></el-table-column> -->
          <el-table-column sortable label="班级" prop="ClassName"> </el-table-column>
        </el-table>
      </div>

      <div v-show="active === 2">
        <div>
          <SectionTitle title="项目信息" />
          <el-form :model="layoutInfo" ref="form" :rules="rules" label-width="100px" :disabled="isView">
            <el-form-item label="项目名称" prop="Name">
              <el-input type="text" v-model="layoutInfo.Name" placeholder="请输入" resize="none" rows="5" class="u-flex-1"></el-input>
            </el-form-item>
            <el-form-item label="项目简介" prop="Description">
              <el-input type="textarea" v-model="layoutInfo.Description" placeholder="请输入" resize="none" rows="5" class="u-flex-1"></el-input>
            </el-form-item>
            <el-form-item label="课程安排" prop="CourseSchedule">
              <el-input type="textarea" v-model="layoutInfo.CourseSchedule" placeholder="请输入" resize="none" rows="5" class="u-flex-1"></el-input>
            </el-form-item>
            <el-form-item label="学习课程" prop="Content">
              <el-select v-model="selectCourseList" multiple placeholder="请选择学习课程" :style="{ width: '100%' }">
                <el-option v-for="(item, index) in courseSeriesOption" :key="index" :label="item.label" :value="item.value" />
              </el-select>
            </el-form-item>
          </el-form>
        </div>
        <!-- <div>
          <SectionTitle title="实践计划" />
          <div class="file">
            <div class="mb-10">附件：</div>
            <FileSelector :isUpload="isView" v-model="filePath" />
          </div>
          <div v-if="isEdit && this.layoutInfo.InternshipPlanInfo">
            文件列表
          </div>
        </div> -->
      </div>
    </div>

    <div class="footer">
      <el-button type="primary" class="mr-16" plain @click="prev" v-if="active !== 0">上一步</el-button>
      <el-button type="primary" class="mr-16" v-if="active !== 2" @click="next">下一步</el-button>
      <el-button type="primary" class="mr-16" plain v-if="active === 2 && !isView" @click="handleSave" :loading="btnLoading">保存</el-button>
      <!-- <el-button type="primary" class="mr-16" v-if="active === 2 && isEdit" @click="handleSubmit" :loading="btnLoading">提交</el-button> -->
      <el-button type="default" v-if="isView" @click="$router.go(-1)">返回</el-button>
      <el-button type="default" v-else @click="goBack">取消</el-button>
    </div>
  </div>
</template>

<script>
import SectionTitle from "@/components/SectionTitle.vue";
import contentTools from "@/mixins/content-tools";
import FileSelector from "@/components/Fileselector";
import {
  getInfo,
  insert,
  update,
  submit,
  getMajorList,
  getGradeList,
  getClassInfoList,
  getStudentList,
  getEnterpriseList,
} from "@/api/internshipLayout";
import { insertProjectClass, updateProjectClass } from "@/api/internshipLayout";
// import { getEnterpriseTeacherList } from "@/api/jobApply";
import { getEnterpriseTeacherList } from "@/api/enterprise/enterpriseStaff";
import { getSchoolTeacherList } from "@/api/teacher";
import { getPageList } from "@/api/student";
import { getOnlineList as getOnlineCourseList } from "@/api/courseseries";
export default {
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
    isCreate: {
      type: Boolean,
      default: false,
    },
    isView: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: "",
    },
    schoolId: {
      type: String,
      default: "",
    },
  },
  components: {
    FileSelector,
    SectionTitle,
  },
  mixins: [contentTools],
  data() {
    return {
      btnLoading: false,
      visibleChange: false,
      active: 0,
      loading: false,
      tableLoading: false,
      majorListloading: false,
      gradeListLoading: false,
      classInfoLoading: false,
      studentTableData: [],
      courseSeriesOption: [],
      EnterpriseTeacherTableData: [],
      SchoolTeacherTableData: [],
      selectStudentList: [],
      selectEnterpriseTeacherList: [],
      selectSchoolTeacherList: [],
      selectCourseList: [],
      layoutInfo: {
        CourseList: [],
      },
      filePath: "",
      majorList: [],
      gradeList: [],
      classInfoList: [],
      enterpriseList: [],
      rules: {
        Date: [
          {
            required: true,
            message: "请选择实践日期",
            trigger: "change",
          },
        ],
        Name: [
          { required: true, message: "请输入项目班名称", trigger: "blur" },
        ],
        Description: [
          { required: true, message: "请输入项目班简介", trigger: "blur" },
        ],
      },
    };
  },
  created() {
    this.initialize();
  },

  methods: {
    async initialize() {
      try {
        this.loading = true;
        await this.getEnterprise();
        // await this.getMajorList();
        await this.getStudent();
        await this.getSchoolTeacherList();
        await this.getOnlineCourseList();
        if (this.isEdit || this.isView) {
          await this.getLayoutInfo();
        }
      } catch (err) {
        console.log("err", err);
      } finally {
        this.loading = false;
      }
    },
    async getLayoutInfo() {
      const { Result } = await getInfo(this.id);
      this.layoutInfo = { ...this.layoutInfo, ...Result };
      // let tempTime = [];
      // tempTime.push(this.formartDate(this.layoutInfo.StartTime, "all"));
      // tempTime.push(this.formartDate(this.layoutInfo.EndTime, "all"));
      // this.layoutInfo.Date = tempTime;
      // await this.getGrade(this.layoutInfo.MajorId);
      // await this.getClassInfo(this.layoutInfo.Grade);
      await this.getStudent(this.layoutInfo.ClassId);
      await this.getEnterpriseTeacherList(this.layoutInfo.EnterpriseId);
      // this.selectStudentList = this.layoutInfo.JobApplyList;
      this.selectStudentList = this.layoutInfo.StudentList.map(
        (item) => item.StudentId
      );
      this.selectEnterpriseTeacherList = this.layoutInfo.TeacherList.filter(
        (item) => item.Type === 0
      ).map((item) => item.TeacherId);
      this.selectSchoolTeacherList = this.layoutInfo.TeacherList.filter(
        (item) => item.Type === 1
      ).map((item) => item.TeacherId);

      //todo:
      // this.selectEnterpriseTeacherList = this.layoutInfo.JobList;

      this.toggleDefaultSelect(
        this.studentTableData,
        this.selectStudentList,
        1
      );
      this.toggleDefaultSelect(
        this.EnterpriseTeacherTableData,
        this.selectEnterpriseTeacherList,
        2
      );
      this.toggleDefaultSelect(
        this.SchoolTeacherTableData,
        this.selectSchoolTeacherList,
        3
      );

      this.selectCourseList = this.layoutInfo.CourseList.map(
        (item) => item.CourseId
      );
    },
    async handleSave() {
      try {
        this.btnLoading = true;
        this.$refs.form.validate(async (valid) => {
          if (valid) {
            // if (!this.selectStudentList.length) {
            //   this.$message.warning("参与学生不能为空");
            //   return;
            // }
            // if (!this.selectEnterpriseTeacherList.length) {
            //   this.$message.warning("参与老师不能为空");
            //   return;
            // }
            this.layoutInfo.StudentIdList = this.selectStudentList;
            // var tempObj = this.majorList.find(el => el.Id === this.layoutInfo.MajorId);
            let params = Object.assign({}, this.layoutInfo, {
              TeacherList: [
                ...this.selectEnterpriseTeacherList.map((item) => ({
                  TeacherId: item,
                  Type: 0,
                })),
                ...this.selectSchoolTeacherList.map((item) => ({
                  TeacherId: item,
                  Type: 1,
                })),
              ],
              StudentList: this.selectStudentList.map((item) => ({
                StudentId: item,
              })),
              CourseList: this.layoutInfo.CourseList.map((item) => ({
                CourseId: item,
              })),
            });
            // params.MajorName = tempObj.MajorBase;
            // params.JobIdList = this.getIdList(this.selectEnterpriseTeacherList);

            // let [startTime, endTime] = this.layoutInfo.Date;
            // params.StartTime = startTime;
            // params.EndTime = endTime;
            params.LayoutCount = this.selectStudentList.length;
            params.SchoolId = this.schoolId;
            // params.InternshipPlan = this.filePath;
            let IsSuccess = false;
            if (!this.isEdit) {
              // params.Status = 0;
              const res = await insertProjectClass(params);
              IsSuccess = res.IsSuccess;
            } else {
              const res = await updateProjectClass(params);
              IsSuccess = res.IsSuccess;
            }
            if (IsSuccess) {
              this.$message.success("保存成功");
              this.$router.go(-1);
            } else {
              this.$message.error("保存失败");
            }
          } else {
            console.log("error submit!!");
            return false;
          }
        });
      } catch (err) {
        console.log(err);
      } finally {
        this.btnLoading = false;
      }
    },
    async handleSubmit() {
      try {
        this.btnLoading = true;
        const res = await submit(this.layoutInfo.Id);
        if (res.IsSuccess) {
          this.$message.success("提交成功");
          this.$router.go(-1);
        }
      } catch (err) {
        console.log(err);
      } finally {
        this.btnLoading = false;
      }
    },
    async getMajorList() {
      const res = await getMajorList();
      if (res.IsSuccess) {
        this.majorList = res.Result;
      }
    },
    async getGrade(val) {
      if (this.visibleChange) {
        this.layoutInfo.Grade = "";
        this.layoutInfo.ClassId = "";
        this.gradeList = [];
        this.classInfoList = [];
        this.studentTableData = [];
      }
      try {
        this.gradeListLoading = true;
        const res = await getGradeList({ majorId: val });
        if (res.IsSuccess) {
          //  去掉重复年级
          this.gradeList = [...new Set(res.Result)];
        }
      } catch (err) {
        console.log(err);
      } finally {
        this.gradeListLoading = false;
      }
    },
    async getClassInfo(val) {
      if (this.visibleChange) {
        this.layoutInfo.ClassId = "";
        this.studentTableData = [];
        this.classInfoList = [];
      }
      try {
        this.classInfoLoading = true;
        const res = await getClassInfoList({
          majorId: this.layoutInfo.MajorId,
          grade: val,
        });
        if (res.IsSuccess) {
          this.classInfoList = res.Result;
        }
      } catch (err) {
        console.log(err);
      } finally {
        this.classInfoLoading = false;
      }
    },
    async getStudent(val) {
      try {
        this.tableLoading = true;
        const { Result: { Data = [] }, } = await getPageList({ PageSize: 10000 });
        Data.map((el) => (el.IsView = this.isView));
        this.studentTableData = Data;
      } catch (err) {
        console.log(err);
      } finally {
        this.tableLoading = false;
      }
    },
    async getOnlineCourseList() {
      try {
        const { Result = [] } = await getOnlineCourseList();
        this.courseSeriesOption = Result.map((item) => ({
          label: item.Name,
          value: item.Id,
        }));
      } catch (err) {
      } finally {
      }
    },
    handleVisibleChange(option) {
      this.visibleChange = option;
    },
    async getEnterprise() {
      const res = await getEnterpriseList();
      if (res.IsSuccess) {
        this.enterpriseList = res.Result;
      }
    },
    async getEnterpriseTeacherList(val) {
      try {
        this.tableLoading = true;
        const { Result = [] } = await getEnterpriseTeacherList(val);
        Result.map((el) => (el.IsView = this.isView));
        this.EnterpriseTeacherTableData = Result;
      } catch (err) {
        console.log(err);
      } finally {
        this.tableLoading = false;
      }
    },
    async getSchoolTeacherList() {
      try {
        this.tableLoading = true;
        const { Result = [] } = await getSchoolTeacherList();
        Result.map((el) => (el.IsView = this.isView));
        this.SchoolTeacherTableData = Result;
      } catch (err) {
        console.log(err);
      } finally {
        this.tableLoading = false;
      }
    },
    handleSelectionStudent(val) {
      this.selectStudentList = val.map((item) => item.Id);
    },
    handleSelectionEnterpriseTeacher(val) {
      this.selectEnterpriseTeacherList = val.map((item) => item.TeacherId);
    },
    handleSelectionSchoolTeacher(val) {
      this.selectSchoolTeacherList = val.map((item) => item.Id);
    },
    getIdList(arr) {
      let list = [];
      arr.forEach((el) => {
        list.push(el.Id);
      });
      return list;
    },
    // 设置表格默认多选
    // params1:表格数据 params2:所选数据
    //params3,1:学生，2：企业老师，3：学校老师
    toggleDefaultSelect(tableData, selectData, type) {
      tableData.forEach((item) => {
        selectData.forEach((el) => {
          switch (type) {
            case 1:
              if (item.Id === el) {
                this.$refs.studentTable.toggleRowSelection(item);
              }
              break;
            case 2:
              if (item.TeacherId === el) {
                this.$refs.EnterpriseTeacherTable.toggleRowSelection(item);
              }
              break;
            case 3:
              if (item.Id === el) {
                this.$refs.SchoolTeacherTable.toggleRowSelection(item);
              }
              break;
            default:
              break;
          }
        });
      });
    },

    selectDate(e) {
      this.$set(this.layoutInfo, "Date", [e[0], e[1]]);
      this.$forceUpdate();
    },

    checkSelectable(row) {
      if (row.IsView) {
        return false;
      } else {
        return true;
      }
    },

    goBack() {
      this.$confirm("此操作将不会保存您所填的信息, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$router.go(-1);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    next() {
      if (this.isView) {
        this.active++;
        return;
      }
      if (this.active === 0) {
        if (!this.layoutInfo.EnterpriseId) {
          this.$message.warning("您没有选择合作企业!");
          return;
        }
        if (this.selectSchoolTeacherList.length + this.selectEnterpriseTeacherList.length) {
          this.active++;
        } else {
          this.$message.warning("您没有选择老师!");
          this.active++;
        }
      } else if (this.active === 1) {
        // this.active++;
        //todo:学生没有选择暂不处理
        if (this.selectStudentList.length) {
          this.active++;
        } else {
          this.$message.warning("您没有选择学生!");
          this.active++;
        }
      } else {
        this.active++;
      }
    },
    prev() {
      this.active--;
    },
  },
};
</script>

<style lang="scss" scoped>
.el-steps {
  width: 850px;
  margin: 0 auto;
  /deep/.el-step__head.is-process {
    .el-step__icon.is-text {
      background-color: #598df3;
      color: #fff;
      border-color: #598df3;
    }
  }
}
.content-box {
  width: 850px;
  height: calc(100% - 140px);
  margin: 0 auto;
  position: relative;
  padding: 0 20px;
  overflow: auto;
}
.number {
  color: #598df3;
}
.disable {
  /deep/.el-table__header tr th .el-checkbox {
    display: none;
  }
}
.footer {
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background: #ffffff;
  box-shadow: 0px -2px 8px 0px rgba(51, 51, 51, 0.08);
  border-radius: 0px 0px 8px 8px;
}
</style>
